import { useEffect, useState } from 'react';
import './ProjectsPage.css';
import { Link } from 'react-router-dom';
import projects from '../requests';
import { Project } from '../types';
import { ProjectHeader } from './ProjectPage';

const ProjectsPage = () => {
  return (
    <div className='Projects'>
      <ProjectHeader />

      <ul className='projects-grid'>
        {projects.map((project, index) => (
          <li key={index} className='projects-grid-item'>
            <ProjectTile
              title={project.name}
              imgSrc={project.images.find(a => a.featured)?.url || ''}
              project={project}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

interface ProjectTileProps {
  title: string;
  imgSrc: string;
  project: Project;
}

export default ProjectsPage;

const ProjectTile = (props: ProjectTileProps) => {
  const { title, imgSrc } = props;
  const [hover, setHover] = useState(false);

  return (
    <Link
      className='project-container'
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      to={`/projects/${encodeURIComponent(title)}`}
    >
      <div className='project'>
        {hover || !imgSrc ? 
          <div className='names-container'><p className='names'>{title}</p></div> :
          <img src={imgSrc} className='imgs' alt={title} />}
      </div>
    </Link>
  );
};
