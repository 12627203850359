import * as React from 'react';
import { Carousel } from 'react-responsive-carousel';
import { Link, useParams } from 'react-router-dom';
import { getProject } from '../requests';
import logo from '../assets/logo.png';
import './ProjectPage.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Project } from 'types';

export const ProjectHeader = () => {

    return (
        <header style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-start',
            zIndex: 1,
            backgroundColor: 'rgba(38, 73, 70, 1)',
            color: 'rgba(219, 211, 165, 1)',
        }}>
            <Link to='/'>
                <img src={logo} style={{
                    maxWidth: '75px',
                    maxHeight: '75px',
                    margin: '16px',
                    animation: '1s ease-out 0s 1 slideInFromLeft',
                }} alt='New Hue R&D Logo' />
            </Link>
            <Link to='/projects' style={{
                marginBottom: 0,
                marginTop: 'auto',
                textDecoration: 'none',
            }}>
                <h1 style={{
                    textTransform: 'uppercase',
                    color: 'rgba(219, 211, 165, 1)',
                }}>Projects</h1>
            </Link>
        </header>
    );
}

const ProjectContent = (project: Project) => {
    return (
        <div className='content'>
            <h2 className='projectName'>{project.name}</h2>
            <hr />
            <h3>Year: <span className='projectYear'>{project.info.date}</span></h3>
            <h3>Location: <span className='projectLocation'>{project.info.location}</span></h3>
            <h3>Category: <span className='projectType'>{project.info.project_type}</span></h3>
            <p className='projectDescription'>{project.info.description}</p>
        </div>
    );
}

const ProjectPage = () => {
    const { title } = useParams();

    const [project, images] = React.useMemo(() => {
        if (title === undefined) {
            return [undefined, []]
        }
        const project = getProject(title);
        if (project === undefined) {
            return [undefined, []]
        }
        const images = project.images.flatMap(i => <img src={i.url} alt={i.alt} crossOrigin='anonymous' />);
        return [project, images];
    }, [title]);

    return (
        <div className='Project'>
            <ProjectHeader />
            <div className='main'>
            {project && <ProjectContent {...project} />}
            {
                images.length > 0 &&
                <Carousel width="38vw" dynamicHeight={true} emulateTouch={true} useKeyboardArrows={true} >
                    {images}
                </Carousel>
            }
            </div>
        </div>
    );
}

export default ProjectPage;
