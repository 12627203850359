import { Project } from './types';
import projects from './assets/data.json';

export const getProject = (title: string): Project | undefined => {
  for (const project of projects) {
    if (project.name === title) {
      return project;
    }
  }
  return undefined;
}

export default projects;