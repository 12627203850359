import React from 'react';
import './ContactForm.css';

const ContactForm = () => {
  // const formSubmit = () => {
  //   fetch('http://localhost:666/v1/contact', {
  //     method: 'POST',
  //     headers: {
  //       'accept': 'application/json',
  //       'Content-Type': 'application/json'
  //     },
  //     body: JSON.stringify()
  //   })
  //     .then((result) => {
  //       console.log(result);
  //     })
  // }

  return (
    <div className='ContactForm'>
      <form action="mailto:info@newhuerestorationdesign.com" method="POST" encType="text/plain">
        <label id='form-title'>Work With Us</label>
        <div id='name-email'>
          <input type='text' name='name' placeholder='Full Name' required={true} />
          <input type='text' name='email' placeholder='Email' required={true} />
        </div>
        <div id='phone-location'>
          <input type='text' name='phone' placeholder='Phone Number' required={true} />
          <input type='text' name='location' placeholder='City, State' required={true} />
        </div>
        <textarea name='description' id='description' rows={5} placeholder='Briefly describe your project' required={true} />
        <input
          type='submit'
          value='Submit'
          className='button'
        />
      </form>
    </div>
  );
};

export default ContactForm;
