import * as React from "react";
import "./HomePage.css";
import { Link } from "react-router-dom";
import ContactForm from "../components/ContactForm";
import logo from "../assets/logo.png";

function HomePage() {
  return (
    <div className="parallax">
      <div className="Home">
        <div id="header">
          <div className="logoImg">
            <img src={logo} className="logo" alt="New Hue R&D Logo" />
          </div>
          <div className="Menu">
            <Link to="/projects" className="MenuItem">
              <span>PROJECTS</span>
            </Link>
            <a href="/#contact" className="MenuItem">
              <span>CONTACT US</span>
            </a> 
          </div>
        </div>
        <div id="about">
          <span className="title">Welcome</span>
          <span className="subtitle">About New Hue Restoration + Design, LLC</span>
          <span className="body">
          New Hue Restoration + Design, LLC is a full service design studio & licensed building firm, with a focus on preservation and revitalization of historic structures. NHR+D aims for every project to reflect our fascination with the historic built environment, and our expertise for people focused spaces. NHR+D team is multi-faceted, and continues to prove excellent craft and thoughtful design with every project. Our team prides itself on its client relationships, and a mission to provide inspiring architectural design. This attention to client desires, project personalization, and management, allows for successful projects to occur.
          <br />
          <br />
          <br />
          <br />
          Our dedication to the meticulousness of construction documents, and to the labor and craft in the field, make us strive for every project to be fluid and effectively productive. As a company with room to grow, our mission is to maintain what has made us successful so far; providing clients with happier, healthier, and rewarding projects. From adaptive reuse, to new construction, New Hue Restoration + Design, LLC is a company devoted to progression in architecture, and exploring the potential in existing structures.
          </span>
          <br />
          <br />
          <span className="services">Services</span>
            <br />
            <div id="services">
<span id="services">- Architectural Design<br /></span>
<span className="tab" >Construction documents, Conceptual Design, Interior Design</span><br />
- Licensed Residential Building | Construction<br />
<span className="tab" >New construction, Remodeling, and Additions</span><br />
- General Contracting<br />
<span className="tab" >Project management, Project Planning, Trades Liaison</span><br />
- Historic Preservation | Restoration | Consulting<br />
<span className="tab" >Material Analysis, Interior/Exterior Historical Design, Construction Thermal Analysis</span><br />
- Custom woodworking | Milling<br />
<span className="tab" >Custom wood Materials, Decking, Railing, Doors, and Windows</span><br />
- Custom Painting | Finishing<br />
<span className="tab" >Plaster Restoration</span><br /></div>

        </div>
        <div id="contact">
          <ContactForm />
        </div>
      </div>
    </div>
  );
}

export default HomePage;
