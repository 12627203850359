import * as React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import ProjectPage from './pages/ProjectPage';
import ProjectsPage from './pages/ProjectsPage';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='projects' element={<ProjectsPage />} />
        <Route path='projects/:title' element={<ProjectPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
